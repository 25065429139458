import * as React from "react";
import {
  Grid,
  Button,
  Box,
  Image,
  useDisclosure,
  Flex,
  VStack,
  Text,
  Slide,
  HStack,
} from "@chakra-ui/react";
import { HiOutlineMenu } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { Global } from "@emotion/react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import logo from "../images/logo.svg";

const Header = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Helmet>
        <title>Alvear — Family office & Secretarial services</title>
        <meta
          name='title'
          content='Alvear — Family office & Secretarial services'
        />
        <meta
          name='description'
          content='We provide broad family office services and secretarial services to companies'
        />
      </Helmet>
      <Box
        px={{ base: "30px", md: "40px", lg: "130px" }}
        py={{ base: "20px" }}
        bg='primaryBlack'
        alignItems={{ lg: "center" }}
        maxH='104px'
        display={{ lg: "flex" }}
        justifyContent='center'
      >
        <Grid
          templateColumns={{ base: "0px 1fr", md: "80px 1fr" }}
          justifyItems={{ base: "center", md: "start" }}
          w={{ lg: "1180px" }}
        >
          <Button
            onClick={onToggle}
            w='40px'
            h='40px'
            bg='transparent'
            padding='5px'
            display={{ md: "none" }}
          >
            <HiOutlineMenu color='#ffffff' size='20px' />
          </Button>
          <Logo />
          <HStack
            justifySelf='end'
            display={{ base: "none", md: "flex" }}
            spacing={{ md: "33px", lg: "57px" }}
          >
            <NavLinks />
          </HStack>
        </Grid>
      </Box>
      <Slide in={isOpen} direction='top' style={{ zIndex: 99 }}>
        <Global
          styles={
            isOpen
              ? `
          html, body {
            overflow: hidden
          }
        `
              : `
        html, body {
          overflow: auto
        }
      `
          }
        />
        <Box width='100vw' height='100vh' bg='menuBg' position='relative'>
          <Flex
            flexDir='column'
            alignItems='center'
            py='20px'
            position='relative'
          >
            <Box mb='92px'>
              <Logo />
            </Box>
            <VStack spacing='48px' textAlign='center'>
              <NavLinks handleOnClick={() => onToggle()} />
            </VStack>
            <Button
              onClick={onToggle}
              bg='transparent'
              padding='5px'
              mt='108px'
            >
              <MdClose color='#528D95' size='25px' />
            </Button>
          </Flex>
        </Box>
      </Slide>
    </>
  );
};

const Logo = () => (
  <Box as={Link} to='/'>
    <Image w={{ lg: "70px" }} h={{ lg: "70px" }} src={logo} alt='Alvear Logo' />
  </Box>
);

const NavLinks = ({ handleOnClick }) => {
  return (
    <>
      <Box
        as={Link}
        to='/'
        onClick={handleOnClick ? () => handleOnClick() : undefined}
      >
        <Text textStyle='menuLink'>Home</Text>
      </Box>
      <Box
        as={AnchorLink}
        to='/#about'
        title='About Alvear'
        onAnchorLinkClick={handleOnClick ? () => handleOnClick() : undefined}
      >
        <Text textStyle='menuLink'>About Alvear</Text>
      </Box>
      <Box
        as={AnchorLink}
        to='/#services'
        title='Services'
        onAnchorLinkClick={handleOnClick ? () => handleOnClick() : undefined}
      >
        <Text textStyle='menuLink'>Services</Text>
      </Box>
      <Box
        as={AnchorLink}
        to='/#contact'
        title='Contact'
        onAnchorLinkClick={handleOnClick ? () => handleOnClick() : undefined}
      >
        <Text textStyle='menuLink'>Contact</Text>
      </Box>
    </>
  );
};

export default Header;
