import * as React from "react";
import { Global } from "@emotion/react";

import SaxvonRegularWoff from "../fonts/SaxvonRegular.woff";
import SaxvonRegularWoff2 from "../fonts/SaxvonRegular.woff2";
import SaxvonTTf from "../fonts/Saxvon.ttf";

import ClarityNuvoLightWoff from "../fonts/ClarityNuvo-Light.woff";
import ClarityNuvoLightWoff2 from "../fonts/ClarityNuvo-Light.woff2";

import ClarityNuvoBoldWoff from "../fonts/ClarityNuvo-Bold.woff";
import ClarityNuvoBoldWoff2 from "../fonts/ClarityNuvo-Bold.woff2";

import ClarityNuvoRegularWoff from "../fonts/ClarityNuvo-Regular.woff";
import ClarityNuvoRegularWoff2 from "../fonts/ClarityNuvo-Regular.woff2";

const Fonts = () => (
  <Global
    styles={`
        @font-face {
          font-family: 'Saxvon';
          src: url(${SaxvonRegularWoff2}) format("woff2"),
              url(${SaxvonRegularWoff}) format("woff"),
              url(${SaxvonTTf}) format('truetype');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
        font-family: 'Clarity Nuvo';
        src: url(${ClarityNuvoLightWoff2}) format('woff2'),
            url(${ClarityNuvoLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
        
    @font-face {
      font-family: 'Clarity Nuvo';
      src: 
          url(${ClarityNuvoBoldWoff2}) format('woff2'),
          url(${ClarityNuvoBoldWoff}) format('woff');
      font-weight: bold;
      font-style: normal;
    }
        
    @font-face {
      font-family: 'Clarity Nuvo';
      src: url(${ClarityNuvoRegularWoff2}) format('woff2'),
          url(${ClarityNuvoRegularWoff}) format('woff');
      font-weight: normal;
      font-style: normal;
    }
      `}
  />
);

export default Fonts;
