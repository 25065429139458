import * as React from "react";
import {
  Flex,
  Box,
  Image,
  Text,
  VStack,
  Grid,
  AspectRatio,
} from "@chakra-ui/react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Fonts from "../components/Fonts";

import topBg from "../images/top-bg-sm.png";
import topBgMd from "../images/top-bg-md.png";
import topBgLg from "../images/top-bg-lg.png";
import chairSm from "../images/chair-sm.png";
import chairLg from "../images/chair-lg.png";
import serviceSM from "../images/service-img-sm.png";
import contactUsBgSM from "../images/contact-us-bg-sm.png";
import contactUsBgLG from "../images/contact-us-bg-lg.png";

import Header from "../components/Header";
import Form from "../components/Form";

const IndexPage = () => {
  return (
    <>
      <Fonts />
      <main>
        <Header />
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          position='relative'
        >
          <Box
            position='absolute'
            bg={`url(${topBgLg}) no-repeat`}
            display={{ base: "none", lg: "block" }}
            height='1500px'
            width='100%'
            backgroundSize='cover'
            top='0'
          />
          <Flex
            bg={{
              base: `url(${topBg}) no-repeat`,
              md: `url(${topBgMd}) no-repeat`,
              lg: "transparent",
            }}
            backgroundSize={{ base: "cover", md: "cover", lg: "cover" }}
            px={{ base: "24px", md: "49px", lg: "130px" }}
            pt={{ base: "100px", md: "136px", lg: "307px" }}
            pb={{ base: "20px" }}
            flexDir='column'
            alignItems='flex-end'
            zIndex='1'
            w='100%'
          >
            <Box
              w={{ lg: "1180px" }}
              justifyContent='flex-end'
              textAlign='right'
            >
              <Text
                textStyle={{
                  base: "paragraph-1",
                  md: "defaultHeader",
                  lg: "mainHeader",
                }}
                textAlign='right'
                boxDecorationBreak='clone'
                bg='primaryBlack'
                p={{ base: "8px", md: "8px 24px" }}
                my='8px'
                display='inline'
                lineHeight={{ base: "2", md: "1.8", lg: "1.9" }}
                as='h1'
                color='buttonColor'
                w={{ base: "293px", md: "664px" }}
                fontFamily='Saxvon'
              >
                What we do: <br /> We provide broad family{" "}
                <Box
                  display={{ base: "none", md: "inline-block", lg: "none" }}
                  as='br'
                />
                office services to companies
              </Text>
            </Box>
            <Box
              as={AnchorLink}
              to='/#contact'
              zIndex='0'
              my={{ base: "20px", md: "56px" }}
              w='164px'
              variant='primary'
              title='Contact us'
              bg='buttonBg'
              padding='16px'
              borderRadius='none'
              textAlign='center'
              textTransform='uppercase'
            >
              <Text textStyle='buttonLabel' as='span'>
                Contact us
              </Text>
            </Box>
          </Flex>
          <Flex
            id='about'
            zIndex='1'
            justifyContent='center'
            alignItems='center'
          >
            <Flex
              bg={{ base: "primaryBlack", md: "transparent" }}
              flexDir={{ base: "column", md: "row" }}
              position='relative'
              px={{ md: "32px" }}
              width={{ md: "704px", lg: "1201px" }}
            >
              <Box
                w={{ md: "448px", lg: "569px" }}
                bg={{ md: "primaryBlack" }}
                px={{ base: "16px", md: "48px", lg: "124px" }}
                pt={{ base: "48px", lg: "177px" }}
                pb={{ base: "80px", lg: "201px" }}
              >
                <Text
                  mb='16px'
                  textStyle={{ base: "buttonLabel", lg: "paragraph-4" }}
                  textTransform={{ md: "uppercase" }}
                  color='subtitleColor'
                >
                  Who we are?
                </Text>
                <Text
                  as='h2'
                  textStyle={{
                    base: "smallTitle",
                    md: "defaultHeader",
                    lg: "mainHeader",
                  }}
                  mb='24px'
                  color='buttonColor'
                  fontFamily='Saxvon'
                >
                  About us
                </Text>
                <Text
                  textStyle={{ base: "paragraph-2", md: "paragraph-3" }}
                  w={{ md: "240px", lg: "324px" }}
                  color='paragraphColor'
                >
                  We are a London based family office providing services to a
                  select group of international companies. We liaise with each
                  of our clients individually, providing back office support to
                  their operations with great attention to detail.
                </Text>
              </Box>
              <Box
                position={{ md: "absolute" }}
                right={{ base: "40px", lg: "20px" }}
                zIndex='1'
                bottom={{ base: "20px", lg: "0" }}
              >
                <Image
                  w={{ base: "100%", md: "320px", lg: "632px" }}
                  alt='Who we are Image'
                  srcSet={`${chairSm} 750w, ${chairSm} 750w, ${chairLg} 1424w`}
                  sizes={
                    "(min-width: 375px) 100vw, (min-width: 768px) 320px, (min-width: 1440px) 632px"
                  }
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          id='services'
          flexDir='column'
          alignItems='center'
          position='relative'
        >
          <Flex
            width='100%'
            alignItems='center'
            position='relative'
            justifyContent='center'
          >
            <Flex flexDir='column' px={{ md: "32px", lg: "125px" }} zIndex='1'>
              <Flex
                flexDir='column'
                alignItems='flex-start'
                px='16px'
                pt='48px'
                pb='56px'
              >
                <Text
                  as='h2'
                  textStyle={{
                    base: "smallTitle",
                    md: "defaultHeader",
                    lg: "mainHeader",
                  }}
                  mb='24px'
                  color='blackText'
                  fontFamily='Saxvon'
                >
                  Our services
                </Text>
                <Text
                  color='blackText'
                  textStyle='paragraph-2'
                  alignSelf={{ md: "center", lg: "flex-start" }}
                  maxW={{ md: "588px", lg: "568px" }}
                  ml={{ lg: "120px" }}
                >
                  We provide operational assistance, family office services to
                  international companies.
                </Text>
              </Flex>
              <Grid
                templateColumns={{ md: "320px", lg: "567px" }}
                gridGap={{ md: "32px" }}
                justifyContent='center'
              >
                <Box
                  w={{ md: "320px", lg: "567px" }}
                  h={{ md: "430px", lg: "680px" }}
                  bg={{ base: "serviceCard", md: "serviceCard2" }}
                  boxShadow={{ md: "0px 32px 40px -24px rgba(0, 0, 0, 0.5)" }}
                >
                  <Image
                    w='100%'
                    src={serviceSM}
                    alt='Family office services Image'
                    h={{ md: "166px", lg: "296px" }}
                  />
                  <Box
                    px='16px'
                    pt='16px'
                    pb='56px'
                    boxShadow={{
                      base: "0px 24px 32px -24px rgba(0, 0, 0, 0.5)",
                      md: "unset",
                    }}
                    display={{ md: "flex" }}
                    flexDir='column'
                    alignItems='center'
                  >
                    <Text textStyle='subtitle-2' mb='24px' color='blackText'>
                      Family office services
                    </Text>
                    <Text
                      maxW={{ md: "224px", lg: "328px" }}
                      color='blackText'
                      textStyle='paragraph-2'
                    >
                      We provide a broad spectrum of private wealth management
                      services and office services tailored to our clients'
                      needs.
                    </Text>
                  </Box>
                </Box>
              </Grid>
            </Flex>
            <Box
              display={{ base: "none", md: "block" }}
              position='absolute'
              bg='#1F2120'
              height={{ base: "350px", lg: "580px" }}
              w='100%'
              bottom='0'
            />
          </Flex>
          <Box
            position='absolute'
            w='100%'
            h='50%'
            bgImage={`url(${contactUsBgLG})`}
            bgRepeat='no-repeat'
            bgColor='addressLabelColor'
            bgSize='cover'
            right='0'
            bottom='0'
            display={{ base: "none", lg: "block" }}
          />
          <Flex
            position='relative'
            flexDir={{ base: "column", md: "row" }}
            alignItems='center'
            bg={{ md: "addressLabelColor" }}
            px={{ md: "32px" }}
            justifyContent='space-between'
            width='100%'
            id='contact'
            maxW={{ lg: "1180px" }}
          >
            <Flex
              bg={{ base: "footerBlack", md: "unset" }}
              position='relative'
              mx={{ md: "32px", lg: "0" }}
              w={{ base: "100%", md: "unset" }}
            >
              <Box
                position='absolute'
                w='100%'
                h='430px'
                bg={`url(${contactUsBgSM}) no-repeat`}
                bgSize='cover'
                right='0'
                top='5%'
                display={{ md: "none" }}
              />
              <Box zIndex='1' w='100%' px='16px' pt='56px' pb='48px'>
                <Text
                  mb='48px'
                  color='buttonColor'
                  textStyle={{
                    base: "smallTitle",
                    md: "defaultHeader",
                    lg: "mainHeader",
                  }}
                  textAlign={{ base: "center", md: "left" }}
                  as='h2'
                  fontFamily='Saxvon'
                >
                  Contact us
                </Text>
                <Form />
              </Box>
            </Flex>
            <Flex
              flexDir='column'
              width={{ base: "100%", md: "320px", lg: "449px" }}
              mt={{ lg: "180px" }}
            >
              <Box alignSelf='stretch' bg='buttonColor' px='16px' py='48px'>
                <VStack spacing='16px' alignItems='flex-start'>
                  <Box>
                    <Text
                      as='h5'
                      mb='8px'
                      textStyle='addressLabel'
                      color='addressLabelColor'
                      fontFamily='Saxvon'
                    >
                      Address
                    </Text>
                    <Text textStyle='paragraph-2' color='addressValueColor'>
                      5 Technology Park, Colindeep Lane, London NW9 6BX
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      as='h5'
                      mb='8px'
                      textStyle='addressLabel'
                      color='addressLabelColor'
                      fontFamily='Saxvon'
                    >
                      Phone
                    </Text>
                    <Text textStyle='paragraph-2' color='addressValueColor'>
                      +44 207 402 6327
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      as='h5'
                      mb='8px'
                      textStyle='addressLabel'
                      color='addressLabelColor'
                      fontFamily='Saxvon'
                    >
                      Email
                    </Text>
                    <Text textStyle='paragraph-2' color='addressValueColor'>
                      contact@alvear.co.uk
                    </Text>
                  </Box>
                </VStack>
              </Box>
              <AspectRatio>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2478.6826405480856!2d-0.25619813366015265!3d51.592379562306256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761136c89db461%3A0x5e5cd4aca137ba14!2sLondon%20NW9%206BX%2C%20UK!5e0!3m2!1sen!2snl!4v1675077569320!5m2!1sen!2snl'
                  loading='lazy'
                  title='Google map'
                ></iframe>
              </AspectRatio>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent='center' alignItems='center'>
          <Box
            bg={{ base: "footerBlack", md: "addressLabelColor" }}
            textAlign='center'
            pt={{ base: "120px", md: "40px" }}
            pb='88px'
            px='16px'
            flex={1}
          >
            <Text textStyle='footerText'>
              © 2021 Alvear. All Rights Reserved
            </Text>
          </Box>
        </Flex>
      </main>
    </>
  );
};

export default IndexPage;
