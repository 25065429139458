import * as React from "react";

import { VStack, Text, Input, Textarea, Button } from "@chakra-ui/react";

const Form = () => {
  return (
    <VStack
      as='form'
      w={{ base: "100%", md: "272px", lg: "449px" }}
      alignItems='flex-start'
      spacing='32px'
    >
      <VStack alignSelf='stretch' alignItems='flex-start' spacing='20px'>
        <Text textStyle='inputLabel' color='buttonColor'>
          Name
        </Text>
        <Input
          textStyle='inputValue'
          type='text'
          placeholder='Your name'
          variant='flushed'
          _placeholder={{
            color: "inputColor",
          }}
          borderColor='inputBorder'
        />
      </VStack>
      <VStack alignSelf='stretch' alignItems='flex-start' spacing='20px'>
        <Text textStyle='inputLabel' color='buttonColor'>
          Email
        </Text>
        <Input
          textStyle='inputValue'
          type='email'
          placeholder='Your email'
          variant='flushed'
          _placeholder={{
            color: "inputColor",
          }}
          borderColor='inputBorder'
        />
      </VStack>
      <VStack alignSelf='stretch' alignItems='flex-start' spacing='20px'>
        <Text textStyle='inputLabel' color='buttonColor'>
          Your message
        </Text>
        <Textarea
          type='email'
          placeholder='Leave us your message here'
          variant='flushed'
          h='100px'
          textStyle='inputValue'
          _placeholder={{
            color: "inputColor",
          }}
          borderColor='inputBorder'
        />
      </VStack>
      <Button
        variant='primary'
        boxShadow='0px 12px 24px -10px rgba(0, 0, 0, 0.5)'
        border='1px solid #F2EBEC'
        alignSelf='flex-end'
        type='button'
      >
        <Text textStyle='buttonLabel' as='span'>
          SEND MESSAGE
        </Text>
      </Button>
    </VStack>
  );
};

export default Form;
